<template>
  <div class="content" style="width: 97%;margin: 0 auto;">
    <div class="content_wrap_x">
      <div class="content_crumbs">
        <el-breadcrumb separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: '/' }">当前位置：集团要闻</el-breadcrumb-item>
          <el-breadcrumb-item>正文</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content_content_yd">
        <div class="content_content_title_yd">
          {{ NewsData.categoryName }}
        </div>
        <div class="content_content_crumbs_yd">
          <div class="content_content_crumbs_time_yd">
            <img src="./../pic/timer.png" alt="">
            发布时间：{{NewsData.updateTime}}
          </div>
        </div>
        <div class="content_content_item_wrap_yd">
          <div class="content_content_item_yd">
            <p v-html="NewsData.content"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  reactive,
  onBeforeMount,
  onUnmounted,
  ref,
  onMounted
} from "vue";
import imgData from "@/data";
import { stateStore, mainStore } from "@/store";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import allUrl from "@/config/url.js";
import { getContentById } from "@/api/newApi/newApi.js";
export default {
  setup() {
    onBeforeMount(() => {
      window.addEventListener("scroll", () => {
        stateStore().setscrollX(document.documentElement.scrollTop);
      });
      const route = useRoute();
      stateStore().selectArticleById(route.query.id);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", () => {
        console.log("remove");
      });
    });
    const fixedPosition = computed(() =>
      storeToRefs(stateStore()).scrollX.value > 400
        ? "position: fixed;top:0;right: 16%;width: 68%;"
        : ""
    );

    const router = useRouter();
    const goHistory = () => {
      router.go(-1);
    };
    const toHref = (item) => {
      const a = document.createElement("a");
      a.href = location.origin + "/" + item.name;
      a.download = item.originalName;
      a.click();
    };
    const goToJoin = () => {
      window.open(allUrl.ServicePlatform);
    };
    const goIndex = () => {
      mainStore().setactivePageUrl("/index");
      router.push({
        path: "/index",
      });
    };
    const NewsData = ref({})
    const idValue = ref('')
    // 子集详情接口
    const http = async () => {
      const res = await getContentById({ id: idValue.value });
      NewsData.value = res.data
      console.log(res, 'res2');
    }
    onMounted(async () => {
      idValue.value = router.currentRoute.value.query.Id;
      await http()
    })
    const data = reactive({
      ...imgData.BiddingInfoImg,
    });

    return {
      ...data,
      goHistory,
      goToJoin,
      goIndex,
      fixedPosition,
      toHref,
      NewsData,
      http,
      idValue
    };
  },
};
</script>

<style scoped>
@import url("./Content.css");
</style>

